<template>
    <v-container class="secondary-font" style="font-size: 0.8rem;">
        <v-row style="max-width: 650px; max-height: 100px; background-color: #131025; color: white;" class="px-0 py-0" align="center" justify="center">
            <v-col cols="1" align="start" class="px-1">
                <div style="display: inline-block;">
                    <v-img width="30px" height="auto" :src="require('@/assets/main_logo.png')" />
                </div>
            </v-col>
            <v-col cols="3" align="start" class="px-0">
                <div style="display: inline-block; font-size: 0.8rem;">
                    <span v-if="!match.cup"> {{ match.league.title }}, </span>
                    <span> Round {{ match.round }}, </span>
                    <span>
                        {{ match.cup? 'Κύπελλο' : (match.phase === 'regular_season' ? 'Regular Season' : (match.phase === 'placements' ? 'Προκριματικά' : 'Play Offs')) }}
                    </span>
                </div>
            </v-col>
            <v-col cols="4" align="center" class="px-0">
                <div class="font-weight-bold">{{ match.team1?.name }}</div>
                <div>
                    <v-icon color="white">mdi-minus</v-icon>
                    <v-icon color="white">mdi-minus</v-icon>
                    <v-icon color="white">mdi-minus</v-icon>
                    <v-icon color="white">mdi-minus</v-icon>
                    <v-icon color="white">mdi-minus</v-icon>
                </div>
            </v-col>
            <v-col cols="2" align="end" class="px-0 py-0 my-0">
                <img
                    :src="`${assetsUrl}/${match.team1?.logo}`"
                    width="35px"
                    height="auto"
                    contain
                >
                <span style="border: 2px solid white;" class="pt-16 pb-2 ml-5" />
            </v-col>
            <!-- <v-col cols=1 class="px-0">
                <span style="border: 3px solid white;" class="py-7"/>
            </v-col> -->
            <v-col cols="2" align="center" style="font-size: 1.3rem;" class="px-0">
                <span>{{ match.team1Score }}</span>
            </v-col>
        </v-row>
        <div style="height: 1px; max-width: 650px; background-color: white;" class="mt-1" />
        <v-row style="max-width: 650px; max-height: 100px; background-color: #131025; color: white;" class="px-0 py-0 my-0" align="center" justify="center">
            <v-col cols="1" style="font-size: 1rem;">
                1st
            </v-col>
            <v-col cols="2" style="font-size: 1rem;">
                09:52
            </v-col>
            <v-col cols="1" style="color: white; background-color: #b03e00; font-size: 1rem;" class="px-0">
                24
            </v-col>
            <v-col cols="4" align="center" class="px-0">
                <div class="font-weight-bold">{{ match.team2?.name }}</div>
                <div>
                    <v-icon color="#b03e00">mdi-minus</v-icon>
                    <v-icon color="#b03e00">mdi-minus</v-icon>
                    <v-icon color="#b03e00">mdi-minus</v-icon>
                    <v-icon color="white">mdi-minus</v-icon>
                    <v-icon color="white">mdi-minus</v-icon>
                </div>
            </v-col>
            <v-col cols="2" align="end" class="px-0 py-0 my-0">
                <img
                    :src="`${assetsUrl}/${match.team2?.logo}`"
                    width="35px"
                    height="auto"
                >
                <span style="border: 2px solid white;" class="pt-16 pb-4 ml-5" />
            </v-col>
            <!-- <v-col cols=1 class="px-0 mx-0">
                <span style="border: 3px solid white;" class="py-7"/>
            </v-col> -->
            <v-col cols="2" align="cenetr" style="font-size: 1.3rem;" class="px-0">
                <span>100</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import matchService from '@/services/match'
    import momentTimezone from 'moment-timezone'
    import moment from 'moment'

    export default {
        data() {
            return {
                polling: null,
                match: {},
                assetsUrl: 'https://storage.googleapis.com'
            }
        },
        beforeDestroy() {
            clearInterval(this.polling)
        },
        created() {
            this.fetchMatch(this.$route.params.id).then(() => {
                const live = momentTimezone().tz('Europe/Athens').add(3, 'hours').locale('el-GR').format('dddd D/M/y, HH:mm')
                const now = momentTimezone().tz('Europe/Athens').locale('el-GR').format('dddd D/M/y, HH:mm')
                if (moment().utc(now).isSameOrBefore(moment().utc(live), 'hour')) {
                    setTimeout(() => {
                        this.polling = setInterval(() => {
                            this.fetchMatch(this.$route.params.id)
                        }, 3000)
                    }, 3000)
                } else {
                    this.fetchMatch(this.$route.params.id)
                }
            })
        },
        methods: {
            fetchMatch(matchId) {
                return matchService.getMatch(matchId).then(resp => {
                    this.match = resp.data
                })
            }
        }
    }
</script>
